import { useSearchParams } from 'react-router-dom';
import { Container, ContentLabel, SectionLabel } from './styles';

const DataDeletionRequest = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    const isFromApp = source === 'webview';

    return (
        <Container isFromApp={isFromApp}>
            <SectionLabel>Yêu cầu xóa tài khoản</SectionLabel>
            <ContentLabel>
                Ở VNLOTTO, chúng tôi tôn trọng quyền riêng tư của bạn và cam kết bảo vệ dữ liệu cá nhân của bạn. Nếu bạn muốn xóa tài khoản và dữ liệu người dùng của mình khỏi hệ thống của chúng tôi, bạn có thể yêu cầu xóa dữ liệu theo hướng dẫn dưới đây.
            </ContentLabel>

            <SectionLabel>Các cách để xóa tài khoản</SectionLabel>
            <ContentLabel>
                <br />
                <b>Gửi Email: </b>
                <br />
                <br />
                1. Gửi một email tới aisteelia369@gmail.com với dòng tiêu đề <b>Yêu cầu xóa tài khoản</b>
                <br />
                <br />
                2. Trong email này, vui lòng bao gồm các thông tin sau:
                <br />
                - Họ và tên đầy đủ của bạn
                <br />
                - Địa chỉ email liên kết với tài khoản Tink của bạn
                <br />
                - Một yêu cầu rõ ràng và cụ thể để xóa dữ liệu người dùng của bạn
                <br />
                <br />
                3. Xác minh: Để đảm bảo yêu cầu là hợp lệ, chúng tôi có thể yêu cầu bạn xác minh danh tính của mình. Chúng tôi sẽ cung cấp hướng dẫn về cách hoàn thành bước này.

                <br />
                <br />
                <br />
                <b>Thực hiện xóa tài khoản trong ứng dụng VNLOTTO: </b>
                <br />
                <br />
                1. Mở ứng dụng VNLOTTO trên thiết bị của bạn
                <br />
                2. Đăng nhập vào tài khoản của bạn
                <br />
                3. Truy cập vào phần hồ sơ ở tab Cài đặt
                <br />
                4. Chọn tùy chọn <b>Xóa tài khoản</b> ở góc dưới cùng màn hình và làm theo hướng dẫn.
            </ContentLabel>

            <SectionLabel>Tiến trình xóa tài khoản</SectionLabel>
            <ContentLabel>
                - Sau khi nhận được yêu cầu xóa tài khoản của bạn, chúng tôi sẽ xác minh thông tin và tiến hành xóa dữ liệu người dùng của bạn khỏi hệ thống của chúng tôi.
                <br />
                - Quá trình này có thể mất một thời gian ngắn để hoàn tất, và bạn sẽ nhận được thông báo khi dữ liệu của bạn đã được xóa thành công.
                <br />
                - Trong vòng 7 ngày kể từ khi bạn thực hiện yêu cầu xóa, bạn vẫn có thể đăng nhập vào tài khoản và quá trình xóa sẽ tự động hủy khi bạn đăng nhập trở lại.
                <br />
                - Sau 7 ngày kể từ khi thực hiện yêu cầu xóa, tất cả dữ liệu liên quan đến tài khoản của bạn sẽ bị xóa vĩnh viễn khỏi hệ thống VNLOTTO.
            </ContentLabel>

            <SectionLabel>Lưu ý quan trọng</SectionLabel>
            <ContentLabel>
                - Khi dữ liệu của bạn đã bị xóa, chúng tôi không thể khôi phục lại dữ liệu này. Điều này có nghĩa là bạn sẽ mất tất cả thông tin, dữ liệu và cài đặt liên quan đến tài khoản của mình.
                <br />
                - Nếu bạn muốn sử dụng dịch vụ của chúng tôi sau khi xóa tài khoản, bạn sẽ cần tạo một tài khoản mới và bắt đầu lại từ đầu.
            </ContentLabel>
        </Container>
    );
}

export default DataDeletionRequest;

import { useSearchParams } from 'react-router-dom';
import { Container, ContentLabel, SectionLabel } from './styles';

const Privacy = () => {
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    const isFromApp = source === 'webview';

    return (
        <Container isFromApp={isFromApp}>
            <SectionLabel>Chính sách quyền riêng tư</SectionLabel>
            <ContentLabel>
                Chào mừng đến với VNLOTTO.
                <br />
                <br />
                Chính sách này giải thích việc sử dụng một số thông tin cá nhân mà chúng tôi có thể đã thu thập từ bạn hoặc bạn đã cung cấp cho chúng tôi trong quá trình sử dụng ứng dụng này.
            </ContentLabel>

            <SectionLabel>Chúng tôi thu thập các thông tin nào về bạn?</SectionLabel>
            <ContentLabel>
                Chúng tôi thu thập thông tin về thiết bị của bạn như ID thiết bị khi bạn sử dụng ứng dụng của chúng tôi. Cũng sẽ có một số thông tin cá nhân mà bạn sẽ chủ động cung cấp cho chúng tôi khi sử dụng ứng dụng như tên đăng nhập, mật khẩu, email.
            </ContentLabel>

            <SectionLabel>Chúng tôi sử dụng các thông tin của bạn như thế nào?</SectionLabel>
            <ContentLabel>
                Thông tin của bạn được chúng tôi sử dụng để xác thực trong quá trình sử dụng ứng dụng này. Nó cũng giúp chúng tôi có được dữ liệu thống kê để cải thiện và phát triển nội dung trong tương lai.
                <br />
                <br />
                Ngoài ra có email được dùng để xác thực tài khoản của bạn trong trường hợp bạn quên mật khẩu đăng nhập. Tuy nhiên việc chúng tôi thu thập email cần có sự cho phép của bạn.
            </ContentLabel>

            <SectionLabel>Chúng tôi chia sẻ thông tin của bạn với ai?</SectionLabel>
            <ContentLabel>
                Thông tin cá nhân của bạn được chúng tôi lưu trữ và bảo mật hoàn toàn. Ngoài ra, một số thông tin nhận dạng thiết bị của bạn được thu thập tự động bởi các dịch vụ của bên thứ ba mà chúng tôi tích hợp vào ứng dụng này.
                <br />
                <br />
                Các dịch vụ của bên thứ ba như Google Admob, Google Pay, Apple Pay, Google Analytics thu thập thông tin của bạn để mua, phân phối quảng cáo và phân tích dữ liệu.
            </ContentLabel>

            <SectionLabel>Quyền riêng tư của trẻ em?</SectionLabel>
            <ContentLabel>
                VNLOTTO không dành cho trẻ em dưới 13 tuổi. Trong một số trường hợp, độ tuổi này có thể cao hơn do yêu cầu quy định của địa phương, vui lòng xem chính sách quyền riêng tư tại địa phương của bạn để biết thêm thông tin.
                <br />
                <br />
                Nếu bạn tin rằng chúng tôi có dữ liệu cá nhân hoặc đã thu thập từ trẻ em dưới độ tuổi phù hợp, vui lòng liên hệ với chúng tôi. Chúng tôi sẽ xóa những thông tin liên quan đến đứa trẻ đó.
            </ContentLabel>
        </Container>
    );
}

export default Privacy;
